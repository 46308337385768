<template>

  <FocusTrap>
    <div class="card" id="myform">

    <div class="card-header header-elements-inline" style="background-color: lightgrey">
      <h5 class="card-title">Area Information</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload"></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <p/>
      <div class="form-group row">
        <label class="col-md-2 col-form-label font-weight-semibold">Name:</label>
        <div class="col-md-10">
          <input  id="txtcode" type="text" class="form-control" placeholder="Enter Name here..." maxlength="100" v-if="area" v-model="area.name" autocomplete="off" autofocus>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-2 col-form-label font-weight-semibold">Parent:</label>
        <div class="col-md-10">
          <select class="form-control" v-if="area" v-model="area.parent_id">
            <option value="1">Primary</option>
            <option v-for="dsgn in parentareas" v-bind:value="dsgn.id">
              {{ dsgn.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-2 col-form-label font-weight-semibold">Type:</label>
        <div class="col-md-10">
          <select class="form-control" v-if="area" v-model="area.type">
            <option value="1">Territory</option>
            <option value="2">Field Area</option>
          </select>
        </div>
      </div>

    </div>

    <div class="card-footer">
      <div class="text-right">
        <button type="button" class="btn btn-primary" @click="saveDocument">Save<i class="icon-paperplane ml-2"></i>
        </button>
      </div>
    </div>

  </div>
  </FocusTrap>
</template>

<script>
  import moment from 'moment-timezone'
  import { userService } from '@/store/auth-header.js'

  export default {
    name: 'AreaForm',
    component: {},
    data () {
      return {
        area: {"id":0,"name":"","type":1,"parent_id":1,"parent_name":""},
        parentareas: []
      }
    },
    props: {
      myarea: {
        type: Object,
        default: () => JSON.parse('{"id":0,"name":"","type":1,"parent_id":1,"parent_name":""}')
      }
    },
    beforeMount () {
      this.area =  JSON.parse(JSON.stringify(this.myarea));
    },
    created () {

    },
    mounted () {
      $('input[type=checkbox]').uniform();
      this.loadAreas();
      $('#txtcode').focus();
    },
    methods: {
      closeWindow () {
        this.$emit('area_window_closed');
      },
      loadAreas () {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode: 'cors',
          headers: userService.authHeader()
        }

        self.$data.parentareas = []
        fetch(`${process.env.VUE_APP_ROOT_API}api/areas`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            if (_.isArray(resp.data)) {
              self.$data.parentareas = resp.data
            }
          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error' })
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
        })

      },
      saveDocument () {
        const self = this;
        self.$data.area.parent_id = parseInt(self.$data.area.parent_id)

        if (self.$data.area.name.trim().length < 1) {
          alert('Invalid Name')
          return
        } else if (self.$data.area.parent_id < 1) {
          alert('Invalid parent')
          return
        }


        const requestOptions = {
          method: (self.$data.area.id == 0 ? 'POST' : 'PUT'),
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.area)
        }

        $('#myform').block({
          msg: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        })

        fetch(`${process.env.VUE_APP_ROOT_API}api/area`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            swal({ title: 'Success!', text: resp.msg, type: 'success', onClose: () => { $('#txtcode').focus()}, timer:1500 })
            self.area = JSON.parse('{"id":0,"name":"","type":1,"parent_id":1,"parent_name":""}')
            self.$emit('area_saved', resp.data);

          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error', timer:3000 })
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error', timer:3000 })
        }).finally(function () {
          $('#myform').unblock()
        })
      }
    }
  }
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }
</style>
