<template>
  <FocusTrap>
    <div class="card" id = "mycard">

    <div class="page-header page-header-light" >
      <div class="page-header-content header-elements-lg-inline" >
        <div class="page-title d-flex" style="padding: 9px;">
          <h6>
            <i class="icon-list mr-2"></i> <span class="font-weight-semibold">Area's </span> - List
          </h6>
          <a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
        </div>

        <div class="header-elements d-none">
          <form action="#">
            <div class="form-group">

              <div class="input-group input-group-feedback input-group-feedback-right wmin-lg-200">
                <input id="txtsearch" type="text" class="form-control wmin-lg-200" placeholder="Search" autocomplete="off" style="padding-left: 20px;">
                <div class="form-control-feedback">
                  <i class="icon-search4"></i>
                </div>

                <div class="input-group-append position-static">
                  <button type="button" class="btn btn-outline-secondary btn-icon" >
                    <i class="icon-gear"></i>
                  </button>
                </div>

              </div>
            </div>
          </form>
        </div>
      </div>
    </div>



    <div class="table-responsive">
      <table id="area-table"
             class="table  table-hover table-bordered table-condensed table-columned"
             data-search="false"
             data-pagination="true"
             data-show-refresh="false"
             data-show-columns="false"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="id" data-class="d-none">id</th>
          <th data-formatter="runningFormatter" data-width="100">S.No</th>
          <th data-field="id" data-class="d-none">Code</th>
<!--          <th data-field="code" data-width="100" data-sorting="true" >Emp Code</th>-->
          <th data-field="name" >Name</th>
          <th data-field="parent_name" >Parent</th>
        </tr>
        </thead>
        <tbody>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
        <!--<div class="dropdown-divider"></div>-->
        <!--<a href="#" class="dropdown-item"><i class="icon-cross2 text-danger"></i>Discard</a>-->
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item"> <i class="icon-pencil3" ></i>Modify</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item"><i class="icon-blocked text-danger"></i>Remove</a>
      </div>
    </div>
    <!-- End of Context Menu -->

    <!-- Clickable menu -->
    <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="99">
      <li>
        <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showModal">
          <i class="fab-icon-open icon-plus3"></i>
          <i class="fab-icon-close icon-plus3"></i>
        </a>
      </li>
    </ul>
    <!-- /clickable menu -->

    <vmodal name="area_window" transition="nice-modal-fade" :delay="100" :resizable="true" width="45%" :height="340" closed="beforeClose" >
      <AreaForm v-bind:myarea="area" v-on:area_saved="loadData" v-on:area_window_closed="closeModal" ></AreaForm>
    </vmodal>


  </div>
  </FocusTrap>
</template>

<script>
  import AreaForm from '@/views/mms/area/AreaForm.vue'
  import FabButton from '@/components/core/FabButton.vue'
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'AreaView',
    components: {
      AreaForm,
      FabButton
    },
    data () {
      return {
        mytable: {},
        area: JSON.parse('{"id":0,"name":"","type":1,"parent_id":1,"parent_name":""}')
      }
    },
    created () {

    },
    mounted () {
      const self = this;

      this.$data.mytable = $('#area-table');

      this.$data.mytable.bootstrapTable();
      $('[data-toggle="popover"]').popover();

      this.$data.mytable.contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {
          let id = $(row.children('*')[0]).text();

          if ($(e.target).text() === 'Modify') {
            self.modifyDocument (id);
          } else if ($(e.target).text() === 'Refresh') {
            self.loadData();
          } else if ($(e.target).text() === 'Remove') {
            self.removeDocument(id);
          }
        }
      });

      $('#txtsearch').keyup(function () {
        let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
        $('#area-table>tbody>tr').show().filter(function () {
          let text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
          return !~text.indexOf(val);
        }).hide();
      });

      self.loadData();
    },
    methods: {
      closeModal () {
        this.$data.area = JSON.parse('{"id":0,"name":"","type":1,"parent_id":1,"parent_name":""}');
        this.$modal.hide('area_window');
      },
      showModal () {
        this.$modal.show('area_window');
      },
      beforeOpen(){

      },
      beforeClose(){
        this.$data.area = JSON.parse('{"id":0,"name":"","type":1,"parent_id":1,"parent_name":""}');
      },
      loadData () {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        this.$modal.hide('area_window');

        $(self.$data.mytable).block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.mytable.bootstrapTable('load',[]);

        fetch(`${process.env.VUE_APP_ROOT_API}api/areas`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            if( _.isArray(resp.data) ){
              self.$data.mytable.bootstrapTable('load', resp.data);
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        }).finally(function () {
          $(self.$data.mytable).unblock();
        })

      },
      modifyDocument (id) {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.area = JSON.parse('{"id":0,"name":"","type":1,"parent_id":1,"parent_name":""}');

        fetch(`${process.env.VUE_APP_ROOT_API}api/area/${id}`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {


            self.$data.area = resp.data;
            self.$modal.show('area_window');
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text:  err.toString() , type:  "error"} );
        });
      },
      removeDocument (id) {
        const self = this;

        self.$data.area.id = id;

        const requestOptions = {
          method: 'DELETE',
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.area)
        };

        swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          timer: 3000,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete tms!'
        }).then((result) => {
          if (result.value) {
            fetch(`${process.env.VUE_APP_ROOT_API}api/area`,requestOptions).then(userService.handleResponse).then(function (resp) {
              self.$modal.hide('area_window')
              self.loadData();
              swal(
                'Deleted!',
                'Your request has been processed',
                'success'
              );
            }).catch(function (err) {
              swal({ title: "Oops", text: err.toString(), type: "error" });
            });
          }
        });
      },
    }
  }
</script>

<style scoped>

</style>
